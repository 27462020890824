// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};
	constructor() {
		let user = JSON.parse(localStorage.getItem('agencyDirector'));
		let name = user.name;
		let role = user.role;
		let agency_type = user.agency_type;
		if (role == 3) {
			if (agency_type == 2) {
				this.config = {
					header: {
						self: {},
						items: [
							{
								title: name
							}
						]
					},
					aside: {
						self: {},
						items: [
							{
								title: 'Dashboard',
								desc: 'Users listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/dashboard',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Programs',
								desc: 'Programs listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/programs',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Programs Request',
								desc: 'Programs listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/programRequestList',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Students',
								desc: 'Students listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/teen',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Manage Subordinate Account Director(s)',
								desc: 'Manage Subordinate Account Director',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/manageSubDirector',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Enrollment',
								desc: 'Enrollment',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/enrollment',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Participants',
								desc: 'Users listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/participants',
								translate: 'MENU.DASHBOARD'
							},

							// {
							// 	title: 'Student Requests',
							// 	desc: 'Student request listing',
							// 	root: true,
							// 	icon: 'flaticon-line-graph',
							// 	page: '/studentRequests',
							// 	translate: 'MENU.DASHBOARD'
							// },
							{
								title: 'Reports',
								desc: 'Reports download checkin and programs',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/reports',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Store',
								desc: 'Manage Store',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/stores',
								translate: 'MENU.DASHBOARD'
							},
							// {
							// 		title: 'Forum',
							// 		desc: 'Manage Store',
							// 		root: true,
							// 		icon: 'flaticon-line-graph',
							// 		page: '/forum',
							// 		translate: 'MENU.DASHBOARD'
							// 	},
							{
								title: 'Provider Points Wallet',
								desc: 'Manage Store',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/wallet',
								translate: 'MENU.DASHBOARD'
							},
							// {
							// 	title: 'Manage Billing Address',
							// 	desc: 'Select billing address and add new billing address',
							// 	root: true,
							// 	icon: 'flaticon-line-graph',
							// 	page: '/selectAddress',
							// 	translate: 'MENU.DASHBOARD'
							// },
							{
								title: 'Manage Billing Details',
								desc: 'Pages listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/cards',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'User Transcript Requests',
								desc: 'User Transcript Requests',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/user-transcript',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Sign Request',
								desc: 'Sign Request',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/sign-request',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Geo Fence',
								desc: 'Geo Fence',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/geo-fence',
								translate: 'MENU.DASHBOARD'
							},
						]
					}
				};

			} else {
				this.config = {
					header: {
						self: {},
						items: [
							{
								title: name
							}
						]
					},
					aside: {
						self: {},
						items: [
							{
								title: 'Dashboard',
								desc: 'Users listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/dashboard',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Programs',
								desc: 'Programs listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/programs',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Programs Request',
								desc: 'Programs listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/programRequestList',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Manage Subordinate Account Director(s)',
								desc: 'Manage Subordinate Account Director',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/manageSubDirector',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Enrollment',
								desc: 'Enrollment',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/enrollment',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Participants',
								desc: 'Users listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/participants',
								translate: 'MENU.DASHBOARD'
							},

							// {
							// 	title: 'Student Requests',
							// 	desc: 'Student request listing',
							// 	root: true,
							// 	icon: 'flaticon-line-graph',
							// 	page: '/studentRequests',
							// 	translate: 'MENU.DASHBOARD'
							// },
							{
								title: 'Reports',
								desc: 'Reports download checkin and programs',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/reports',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Store',
								desc: 'Manage Store',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/stores',
								translate: 'MENU.DASHBOARD'
							},
							// {
							// 		title: 'Forum',
							// 		desc: 'Manage Store',
							// 		root: true,
							// 		icon: 'flaticon-line-graph',
							// 		page: '/forum',
							// 		translate: 'MENU.DASHBOARD'
							// 	},
							{
								title: 'Provider Points Wallet',
								desc: 'Manage Store',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/wallet',
								translate: 'MENU.DASHBOARD'
							},
							// {
							// 	title: 'Manage Billing Address',
							// 	desc: 'Select billing address and add new billing address',
							// 	root: true,
							// 	icon: 'flaticon-line-graph',
							// 	page: '/selectAddress',
							// 	translate: 'MENU.DASHBOARD'
							// },
							{
								title: 'Manage Billing Details',
								desc: 'Pages listing, details and action',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/cards',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'User Transcript Requests',
								desc: 'User Transcript Requests',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/user-transcript',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Sign Request',
								desc: 'Sign Request',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/sign-request',
								translate: 'MENU.DASHBOARD'
							},
							{
								title: 'Geo Fence',
								desc: 'Geo Fence',
								root: true,
								icon: 'flaticon-line-graph',
								page: '/geo-fence',
								translate: 'MENU.DASHBOARD'
							},
						]
					}
				};
			}
		} else if (role == 5) {
			this.config = {
				header: {
					self: {},
					items: [
						{
							title: name
						}
					]
				},
				aside: {
					self: {},
					items: [
						{
							title: 'Programs',
							desc: 'Programs listing, details and action',
							root: true,
							icon: 'flaticon-line-graph',
							page: '/programs',
							translate: 'MENU.DASHBOARD'
						},
						{
							title: 'Programs Request',
							desc: 'Programs listing, details and action',
							root: true,
							icon: 'flaticon-line-graph',
							page: '/programRequestList',
							translate: 'MENU.DASHBOARD'
						},
						{
							title: 'Participants',
							desc: 'Users listing, details and action',
							root: true,
							icon: 'flaticon-line-graph',
							page: '/participants',
							translate: 'MENU.DASHBOARD'
						},
						{
							title: 'Store',
							desc: 'Manage Store',
							root: true,
							icon: 'flaticon-line-graph',
							page: '/stores',
							translate: 'MENU.DASHBOARD'
						},
						{
							title: 'Students',
							desc: 'Students listing, details and action',
							root: true,
							icon: 'flaticon-line-graph',
							page: '/teen',
							translate: 'MENU.DASHBOARD'
						}
					]
				}
			};
		}

	}
}

