import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'm-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent implements OnInit {
  public user:any;
  public show: boolean = false;
  public href: string = "";
  constructor(private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('agencyDirector'));
    this.href = this.router.url;
    if(this.href == '/noaccess'){
      this.show = true;
    }
  }

}
