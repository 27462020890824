import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class PlanGuard implements CanActivate {
  permissions: any ={};
  constructor(private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = JSON.parse(localStorage.getItem('agencyDirector'));
     let role = user.role;
    let redirectToAddress = false;
    if(user.permissions){
      this.permissions = user.permissions;
    }
    for(let i=0; i<=next.data.role.length;i++){
        if (role === next.data.role[i]) {
            if(next.data.role[i]==3){
               redirectToAddress = true;
            }
            else if(next.data.role[i]==5){
              if(state.url.includes("programs")){
                   redirectToAddress = true;
              }
              else if(state.url.includes("viewProgram")){
                redirectToAddress = true;
              }
              else if(state.url.includes("videos")){
                redirectToAddress = true;
              }
              else  if(state.url.includes("addProgram")){
                  if(this.permissions['add_program'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
              else if(state.url.includes("editProgram")){
                  if(this.permissions['edit_program'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("participants")){
                  if(this.permissions['participants'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("view-participants")){
                  if(this.permissions['participants'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("stores")){
                  if(this.permissions['store'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("viewProduct")){
                  if(this.permissions['store'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("addProduct")){
                  if(this.permissions['store'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("editProduct")){
                  if(this.permissions['store'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("teen")){
                  if(this.permissions['student'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
                else if(state.url.includes("view-teen")){
                  if(this.permissions['student'] == true){
                     redirectToAddress = true;
                  }
                  else{
                    this._router.navigate(['/noaccess']);
                    return false;
                  }
                }
            }
         } 
         if(redirectToAddress == true){
           return true;
         }
      }
    }
}